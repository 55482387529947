import { render, staticRenderFns } from "./Applicant-Onboarding-Two.vue?vue&type=template&id=60376d49&scoped=true&"
import script from "./Applicant-Onboarding-Two.vue?vue&type=script&lang=js&"
export * from "./Applicant-Onboarding-Two.vue?vue&type=script&lang=js&"
import style0 from "./Applicant-Onboarding-Two.vue?vue&type=style&index=0&id=60376d49&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60376d49",
  null
  
)

export default component.exports