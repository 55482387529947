<template>
  <div>
    <b-container fluid class="applicant-onboard user-onboard customized-form mt-5 pt-5">
      <div class="header">
        <div>
          <h4 class="user-welcome pb-4">Welcome, User</h4>
          <div class="profile-box">
            <div class="profile-box-header">
              <h4 class="profile-box-title">
                Improve <br />
                your profile
              </h4>
              <div>
                <radial-progress-bar :diameter="90" :stroke-width="8" :inner-stroke-width="10" inner-stroke-color="#fff" start-color="#6244e5" stop-color="#6244e5" :completed-steps="formComplete" :total-steps="totalSteps">
                  <p class="f-primary mb-0 font-weight-bold">{{ formComplete }}%</p>
                </radial-progress-bar>
              </div>
            </div>
            <div class="profile-box-content">
              <p>Add the following data to your profile to reach 100% and maximise your chance of getting recognised by employers</p>

              <div class="profile-box-step">
                <p :class="personal_details.photo || $store.getters.getPreviewImage ? 'f-light strike-through' : 'fpn-b text-dark'">Add Profile Photo</p>
                <p :class="personal_details.country ? 'f-light strike-through' : 'fpn-b text-dark'">Add Country Of Residence</p>
                <p :class="personal_details.industry ? 'f-light strike-through' : 'fpn-b text-dark'">Add Industry</p>
                <p :class="personal_details.preferred_job ? 'f-light strike-through' : 'fpn-b text-dark'">Add Preferred Job</p>
                <p :class="current_job.job_org != '' ? 'f-light strike-through' : 'fpn-b text-dark'">Add Current Org</p>
                <p :class="current_job.skills && current_job.skills.length > 0 ? 'f-light strike-through' : 'fpn-b text-dark'">Add Skills</p>
                <p :class="dob ? 'f-light strike-through' : 'fpn-b text-dark'">Add DOB</p>
              </div>
            </div>
          </div>
        </div>
        <div class="step-heading">
          <figure>
            <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="50px" />
          </figure>
          <div class="pt-2">
            <h5 class="step-number">STEP 2</h5>
            <h4 class="step-name">Enter your details</h4>
          </div>
        </div>
      </div>
      <ValidationObserver ref="personal" tag="div" class="content row px-3">
        <div class="col-md-7 col-lg-7 col-xl-8 details-grid-one py-3">
          <div class="left">
            <div>
              <label>LOCATION</label>
              <div class="grid-half">
                <ValidationProvider name="country" rules="required" v-slot="{ errors, classes }">
                  <!-- <multiselect
                    style=""
                    class="form-control"
                    v-model="personal_details.country"
                    placeholder="Choose Country"
                    :class="classes"
                    @input="onChangeCountry"
                    :options="countryOptions"
                  /> -->
                  <multiselect style="" class="multis mb-3" :class="classes" v-model="personal_details.country" placeholder="Select Country" @input="onChangeCountry" @remove="removeCountry" label="label" track-by="country_id" :options="countryOptions" :multiple="false" />

                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>

                <ValidationProvider name="city" rules="required" v-slot="{ errors, classes }">
                  <!-- <v-select
                    style=""
                    class="form-control"
                    :class="classes"
                    v-model="personal_details.city"
                    placeholder="Choose City"
                    :options="cities"
                  /> -->
                  <multiselect style="" class="multis mb-3" :class="classes" v-model="personal_details.city" placeholder="Select City" label="label" track-by="city_id" :options="cities" :multiple="false" />
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div>
              <label>INDUSTRY</label>
              <ValidationProvider name="industry" rules="required" v-slot="{ errors, classes }">
                <v-select v-model="personal_details.industry" class="form-control" :class="classes" :options="industry" placeholder="Choose Industry"></v-select>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="mb-2">
              <label>NATIONALITY</label>
              <ValidationProvider name="nationality" rules="required" v-slot="{ errors, classes }">
                <!-- <v-select
                  v-model="personal_details.nationality"
                  class="form-control"
                  :class="classes"
                  :options="nationalityOptions"
                  placeholder="Choose Nationality"
                ></v-select> -->
                <multiselect style="" class="multis" :class="classes" v-model="personal_details.nationality" placeholder="Select Nationality" label="nationality" track-by="nationality_id" :options="nationalityOptions" :multiple="false" />
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="mb-2">
              <label>PREFERRED JOB</label>
              <ValidationProvider name="preferred_job" rules="required" v-slot="{ errors, classes }">
                <multiselect v-model="personal_details.preferred_job" class="multis" :class="classes" :options="job_title" label="job_title" track-by="job_title" :multiple="false" placeholder="Type Preferred" />
                <!-- @input="selectJobTitle" -->
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div>
              <label>PHONE NUMBER</label>
              <ValidationProvider name="phone_number" rules="required|min-4:4" v-slot="{ errors, classes }">
                <div class="d-flex w-100 mb-3">
                  <VuePhoneNumberInput
                    v-model="personal_details.phone_number"
                    class="w-100 phone_number_input"
                    :class="personal_details.phone_number ? classes : ''"
                    :preferred-countries="['SA', 'AE', 'QA', 'BH', 'OM']"
                    size="md"
                    :default-country-code="personal_details.phone_country_code"
                    @update="updatePhoneNumber"
                    :no-validator-state="false"
                    :no-example="false"
                  />
                </div>

                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="right border-left pl-lg-4">
            <label>PHOTO</label>
            <div class="custom-upload upload-applicant-photo text-center">
              <img v-if="!$store.getters.getPreviewImage && !personal_details.photo" src="@/assets/app-icons/cloud-upload.svg" class="img-fluid" alt="upload-icon" width="50px" />
              <div>
                <div class="text-center" v-if="$store.getters.getPreviewImage">
                  <img :src="$store.getters.getPreviewImage" alt="" width="80" class="img-fluid" />
                </div>
                <div class="text-center" v-if="!$store.getters.getPreviewImage && personal_details.photo">
                  <img v-if="!$store.getters.getPreviewImage" :src="personal_details.photo" alt="" width="80" />
                </div>

                <label for="file-upload" class="btn btn-primary upload-btn"> UPLOAD A PHOTO </label>
                <div class="upload-max">Max 20MB</div>
                <input id="file-upload" ref="fileInput" type="file" @input="onFileChange" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 details-grid-two border-top py-3">
          <div class="border-right pr-lg-4">
            <div>
              <label> CURRENT ORGANISATION</label>
              <ValidationProvider name="current_org" rules="" v-slot="{ errors, classes }">
                <b-form-input v-model="current_job.job_org" :class="current_job.job_org ? classes : ''"> </b-form-input>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <label> CURRENT JOB TITLE</label>
              <ValidationProvider name="job_title" rules="" v-slot="{ errors, classes }">
                <b-form-input v-model="current_job.job_title" :class="current_job.job_title ? classes : ''"> </b-form-input>
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div>
              <div class="row">
                <div class="col-12 col-lg-6" style="">
                  <label> YEARS OF EXPERIENCE</label>
                  <ValidationProvider name="experiance" rules="required" v-slot="{ errors, classes }">
                    <b-form-input placeholder="Years" type="number" v-model="current_job.experiance" :class="classes"> </b-form-input>
                    <!-- <multiselect
                      style=""
                      class="multis"
                      :class="classes"
                      v-model="current_job.experiance"
                      :options="['0-2', '2-4', '4-6', '6-8', '8-10']"
                      :multiple="false"
                      placeholder="Experience"
                    /> -->
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-2">
                  <label>SPECIALITY (Optional)</label>
                  <ValidationProvider name="speciality" rules="" v-slot="{ errors, classes }">
                    <multiselect style="" class="multis" v-model="speciality" placeholder="Select Speciality" label="checkbox" track-by="checkbox_id" :options="specialityOpt" :multiple="false" @input="changeSpeciality" />
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>

                <!-- <div class="col-6" style="width: 20px">
                  <ValidationProvider
                    name="experience_months"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <b-form-input
                      placeholder="Months"
                      type="number"
                      v-model="current_job.experience_months"
                      :class="classes"
                    >
                    </b-form-input>
                    <div class="error-msg" :class="classes">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div> -->
              </div>
            </div>
          </div>
          <div class="border-right px-lg-4">
            <label>INTRO</label>
            <ValidationProvider name="intro" rules="required|max:800" v-slot="{ errors, classes }">
              <b-form-textarea class="w-100" placeholder="Introduce yourself" rows="6" max-rows="10" v-model="current_job.introduction"> </b-form-textarea>
              <div class="error-msg" :class="classes">
                <span>{{ errors[0] }}{{ errors[0] ? ". Max limit is 800" : "" }}</span>
              </div>
            </ValidationProvider>
            <div class="row">
              <div class="col-sm-6 mb-2">
                <label>CURRENCY</label>
                <ValidationProvider name="currency" rules="required" v-slot="{ errors, classes }">
                  <multiselect style="" class="multis" :class="classes" v-model="personal_details.currency" placeholder="Select Currency" label="currency_name" track-by="currency_id" :options="currencyOptions" :multiple="false" />
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-sm-6 mb-2">
                <label>EXPECTED SALARY (Annually)</label>
                <ValidationProvider name="expectedSal" rules="required" v-slot="{ errors, classes }">
                  <b-form-input type="number" v-model="current_job.exp_salary" :class="classes"> </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mb-2">
                <ValidationProvider name="jobType" rules="required" v-slot="{ errors, classes }">
                  <multiselect style="" class="multis" :class="classes" v-model="current_job.available" placeholder="Your Availablity" :options="['Full Time', 'Part Time', 'Remote', 'Internship', 'Contract', 'Work From Home']" @input="addAvail" @remove="removeAvail" :multiple="true" />
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="pl-lg-4">
            <div style="height: auto" class="mb-2">
              <label>SKILLS</label>
              <div v-if="current_job.skills && current_job.skills.length > 0" class="skills-label">
                <label v-for="(skill, skillIndex) in current_job.skills" :key="skillIndex"
                  >{{ skill.label }}
                  <span class="padding-left:20px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" @click="removeSkill(skill, skillIndex)">
                      <path
                        fill="#c3c3c3"
                        d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                      ></path>
                    </svg>
                  </span>
                </label>
              </div>
              <ValidationProvider name="skills" rules="required" v-slot="{ errors, classes }">
                <multiselect
                  style=""
                  class="multis"
                  :class="classes"
                  v-model="current_job.skills"
                  placeholder="Select Skills"
                  label="label"
                  @input="addSkills"
                  @remove="removedSkills"
                  track-by="skill_id"
                  :options="skillOptions"
                  :max="10"
                  :multiple="true"
                  :taggable="true"
                  @tag="addTag"
                  :limit="0"
                  :limit-text="limitText"
                />

                <!-- <vue-simple-suggest
                  placeholder="Job Title"
                  class="suggest suggest-input form-control"
                  v-model="current_job.skills"
                  :list="skillOptions.map((m) => m.label)"
                  :min-length="1"
                  :filter-by-query="true"
                  :value-attribute="'job_title_id'"
                  :display-attribute="'job_title'"
                  :preventHide="true"
                  :class="current_job.skills ? 'suggest-input-error' : ''"
                >
                </vue-simple-suggest> -->
                <div class="error-msg" :class="classes">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <label>DATE OF BIRTH</label>
            <ValidationProvider name="dob" rules="required" v-slot="{ errors, classes }">
              <datepicker placeholder="Date Of Birth" v-model="dob" class="custom-date" :class="classes" @selected="onSelectDate"></datepicker>
              <div class="error-msg" :class="classes">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <br />
            <label>GENDER</label>
            <ValidationProvider name="dob" rules="required" v-slot="{ errors, classes }">
              <multiselect style="" class="multis" :class="classes" v-model="current_job.gender" placeholder="Select Gender" :options="['Male', 'Female', 'Other']" :multiple="false" />
              <div class="error-msg" :class="classes">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <!-- <div class="col-md-12">
          <div class="header d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="step-heading">
                <figure>
                  <img
                    src="@/assets/app-icons/cv.svg"
                    class="img-fluid pr-3"
                    alt="cv-icon"
                    width="50px"
                  />
                </figure>
                <div class="pt-2">
                  <h5 class="step-number">STEP 3</h5>
                  <h4 class="step-name">Questions</h4>
                </div>
              </div>
              <div class="pl-5">
                <button class="btn btn-previous">SKIP</button>
              </div>
            </div>

            <div class="bg-grey rounded-lg py-2 col-3">
              <div class="d-flex align-items-center">    
                <button v-b-toggle.my-collapse class="d-flex align-items-center btn btn-default">
                  <div class="qst-icon mr-2">?</div>
                  <h5 class="fmp-b mb-0">TIPS</h5>
                </button>
              </div>
              <b-collapse id="my-collapse">
                  
                </b-collapse>
            </div>
          </div>
          <div class="">
            <div class="border-bottom py-4 question-block">
              <h5 class="question-heading">
                Q1. A generic AI - driven question goes here?
              </h5>
              <b-form-radio-group class="radio-square question-option">
                <b-form-radio value="first">Yes</b-form-radio>
                <b-form-radio value="second">No</b-form-radio>
              </b-form-radio-group>
            </div>
            <div class="border-bottom py-4 question-block">
              <h5 class="question-heading">
                Q1. Multiple Choice question goes here?
              </h5>
              <b-form-radio-group class="radio-square question-option">
                <b-form-radio value="first">Option 1</b-form-radio>
                <b-form-radio value="second">Option 2</b-form-radio>
                <b-form-radio value="third">Option 3</b-form-radio>
                <b-form-radio value="fourth">Option 4</b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div> -->
      </ValidationObserver>

      <div class="footer">
        <div>
          <button
            class="btn btn-previous mr-5 mb-2 mb-lg-0"
            @click="
              $router.push({
                path: '/applicant-onboarding-1',
              })
            "
          >
            BACK
          </button>
          <button @click="resetOnBoardingTwo" class="btn btn-previous mr-5 mb-2 mb-lg-0">RESET</button>

          <button class="btn btn-next w-auto" @click.prevent.stop="ValidateInput">SAVE PROFILE</button>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Navigation from "./Navigation.vue";
import ApplicantProfile from "@/apiServices/ApplicantProfileService.js";
import LocationService from "@/apiServices/LocationServices";
import MasterServices from "@/apiServices/MasterServices";
import Datepicker from "vuejs-datepicker";
import RadialProgressBar from "vue-radial-progress";
import moment from "moment";
import Vue from "vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    Navigation,
    Multiselect,
    Datepicker,
    RadialProgressBar,
    VuePhoneNumberInput,
  },
  data() {
    return {
      // previewImage: null,
      completedSteps: 0,
      totalSteps: 100,
      photo: null,
      job_title: [],
      industry: [],
      selectedJobTitle: null,
      city: [],
      cities: [],
      countryOptions: [],
      nationalityOptions: [],
      skillOptions: [],
      currencyOptions: [],
      specialityOpt: [],
      speciality: null,
      dob: null,
      personal_details: {
        photo: null,
        country: null,
        city: null,
        industry: null,
        nationality: null,
        preferred_job: null,
        age: null,
        dob: null,
        currency: null,
        phone_number: null,
        phone_country_code: "AE",
        phone_country_number: "971",
      },
      current_job: {
        job_title: null,
        job_org: null,
        introduction: null,
        skills: [],
        currency: null,
        delSkills: [],
        experiance: null,
        gender: null,
        // experience_months: null,
        speciality_id: null,
        exp_salary: null,
        available: [],
        delAvail: [],
      },
    };
  },
  // computed: {
  //   personal_details: {
  //     get() {
  //       return this.$store.state.personal_details;
  //     },
  //     set(personal_details) {
  //       this.$store.commit("SET_APPLICANT_PERSONAL_VALUES", personal_details);
  //     },
  //   },
  // },
  beforeRouteLeave(to, from, next) {
    console.log("to:::::::::::: :>> ", to);
    console.log("from::::::::::: :>> ", from);
    if (to.name == "Applicant-Onboarding-1") {
      this.$store.commit("SET_PREFILLED_FLAG2");
      this.addPersonalDetails();
      this.addCurrentJob();
      next();
    } else if (to.name != "Applicant-Onboarding-3" && to.name != "Applicant-Onboarding-4") {
      this.$store.commit("RESET_PREFILLED_FLAG");
      this.$store.commit("RESET_PREFILLED_FLAG2");
      next();
    } else next();
  },
  beforeMount() {
    this.getAllSkills();
    this.getAllCountry();
    this.getAllIndustry();
    this.getAllJobTitles();
    this.getAllCurrenct();
    this.getAllNationality();
    this.getAllSpeciality();
    console.log("this.$store.getters.getCompletedSteps :>> ", this.$store.getters.getCompletedSteps);
    this.completedSteps = this.$store.getters.getCompletedSteps ? this.$store.getters.getCompletedSteps : 0;
    // this.prefilledDataFromStore();
    const edu = this.$store.getters.getApplicantEducation;
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // vm.prevRoute = from;
      console.log("from :>> ", from);
    });
  },

  mounted: function () {
    this.prefilledData();
    console.log(this.personal_details);
  },
  computed: {
    formComplete() {
      this.completedSteps = this.$store.getters.getCompletedSteps ? this.$store.getters.getCompletedSteps : 0;
      let steps = 0;

      if (this.personal_details.photo) {
        steps = 10;
      }
      if (this.personal_details.country && this.personal_details.industry && this.personal_details.nationality) {
        steps = steps + 20;
      }
      if (this.current_job.job_title) {
        steps = steps + 10;
      }
      if (this.current_job.skills && this.current_job.skills.length > 0) {
        steps = steps + 10;
      }
      this.completedSteps = this.completedSteps + steps;
      return this.completedSteps;
    },
  },
  methods: {
    updatePhoneNumber(data) {
      console.log(`data`, data);
      this.personal_details.phone_number = data.phoneNumber;
      this.personal_details.phone_country_number = data.countryCallingCode || null;
      this.personal_details.phone_country_code = data.countryCode || null;
    },
    removeSkill(skill, index) {
      this.current_job.skills.splice(index, 1);
      this.current_job.delSkills.push(skill.skill_id);
    },
    addTag(e) {
      console.log("e :>> ", e);
      this.current_job.skills.push({ label: e, skill_id: null });
    },
    limitText(count) {
      let text = null;
      count == 1 ? (text = `${count} skill selected`) : (text = `${count} skills selected`);
      return text;
    },
    getAllNationality() {
      ApplicantProfile.GetAllNationality().then((res) => {
        this.nationalityOptions = res.data.data;
      });
    },
    getAllSpeciality() {
      ApplicantProfile.GetAllSpeciality().then((res) => {
        this.specialityOpt = res.data.data;
      });
    },
    addAvail(e) {
      const addedAvail = e;
      this.current_job.delAvail = this.current_job.delAvail.filter((f) => {
        if (!addedAvail.includes(f)) {
          return true;
        }
      });
    },
    removeAvail(e) {
      console.log("e :>> ", e);
      this.current_job.delAvail.push(e);
      console.log("this.current_job.delAvail :>> ", this.current_job.delAvail);
    },
    getAllCurrenct() {
      ApplicantProfile.GetCurrency().then((res) => {
        
        this.currencyOptions = res.data.data;
      });
    },
    getAllJobTitles() {
      ApplicantProfile.GetAllJobTitles().then((res) => {
        this.job_title = res.data.data;
      });
    },
    resetOnBoardingTwo() {
      this.dob = null;
      this.personal_details = {
        photo: null,
        country: null,
        city: null,
        industry: null,
        nationality: null,
        preferred_job: null,
        age: null,
        dob: null,
      };
      this.current_job = {
        job_title: null,
        job_org: null,
        introduction: null,
        skills: [],
        experiance: null,
        // experience_months: null,
        exp_salary: null,
      };
    },
    changeSpeciality(e) {
      console.log("e :>> ", e);
      this.current_job.speciality_id = e.checkbox_id;
    },
    removeCountry(e) {
      console.log("e :>> ", e);
      const country_id = [e.country_id];
      LocationService.GetAllCityOfCountry(country_id).then((res) => {
        
        const city = res.data.data.map((m) => {
          return m.city_id;
        });
        console.log("city :>> ", city);
        if (city.includes(this.personal_details.city.city_id)) {
          this.personal_details.city = null;
        }
      });
    },
    selectJobTitle() {
      this.personal_details.preferred_job = this.selectedJobTitle.job_title;
    },
    getAllCountry() {
      LocationService.GetAllCountry({}).then((res) => {
        this.countryOptions = res.data.data.map((m) => {
          return { label: m.country_name, country_id: m.country_id };
        });
      });
    },
    onChangeCountry(e) {
      if (e) {
        const country_id = [e.country_id];
        LocationService.GetAllCityOfCountry(country_id).then((res) => {
          
          this.cities = res.data.data.map((m) => {
            return {
              label: m.city_name,
              city_id: m.city_id,
            };
          });
          const c = this.cities.map((m) => {
            return m.city_id;
          });
          console.log(c);
          if (this.personal_details.city) {
            const p = c.includes(this.personal_details.city.city_id);
            console.log("p :>> ", p);
            if (!p) {
              this.personal_details.city = null;
            }
          }
        });
      }
    },
    getAllIndustry() {
      MasterServices.GetAllIndustry().then((res) => {
        
        this.industry = res.data.data.map((resp) => {
          return { label: resp.industry_name, industry_id: resp.industry_id };
        });
      });
    },
    async getResumeParserData() {
      const user_id = this.$cookies.get("user_id");
      const getParser = await ApplicantProfile.getUploadCv({
        user_id,
      });
      console.log("getParser.data.data.parser :>> ", getParser.data.data.parser);
      let length = getParser.data.data.parser.data.workExperience.length - 1;
      this.current_job.job_title = getParser.data.data.parser.data.workExperience[length].jobTitle;
      this.current_job.job_org = getParser.data.data.parser.data.workExperience[length].organization;
      console.log("this.current_job.job_org :>> ", this.current_job.job_org);
      this.current_job.introduction = getParser.data.data.parser.data.summary;
      this.dob = moment(getParser.data.data.parser.data.dateOfBirth).format("YYYY-MM-DD HH:mm:ss");
    },
    ValidateInput() {
      this.$refs.personal.validate().then((result) => {
        console.log("result :>> ", result);
        if (result) {
          if (this.personal_details.phone_country_code && this.personal_details.phone_country_number) {
            this.$store.commit("SET_PREFILLED_FLAG2");
            console.log("succes");
            this.addPersonalDetails();
            this.addCurrentJob();
            this.submit();
            //this.$router.push({ name: "Applicant-Onboarding-3" });
            return true;
          } else {
            this.$toasted.show("Please select phone country code", {
              position: "top-right",
              duration: 6000,
              icon: "clear",
            });
          }
        } else {
          console.log("this.personal_details :>> ", this.personal_details);
          this.$toasted.show("Some Fields are missing!", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
          throw new Error("invalid form");
        }
      });
    },
    async submit() {
      const exp = this.$store.getters;
      console.log("userId-->", this.user_id);
      console.log("this.$store.getters.getApplicantOtherSocialLink :>> ", this.$store.getters.getApplicantOtherSocialLink);

      const postData = new FormData();

      let profile = {
        experiance: [...exp.getApplicantExperiance],
        education: [...exp.getApplicantEducation],
        certificates: [...exp.getApplicantCertificates],
        socialLink: JSON.stringify(JSON.stringify({ ...exp.getApplicantSocialLink })),
        other_links: JSON.stringify(exp.getApplicantOtherSocialLink),
        personalDetails: { ...exp.getApplicantPersonalDetails },
        currentJob: { ...exp.getApplicantCurrentJob },
      };
  
      if (profile.personalDetails.photo) {
        console.log("hitting with  photo");
        postData.append("experiance", JSON.stringify(profile.experiance));
        postData.append("education", JSON.stringify(profile.education));
        postData.append("socialLink", JSON.stringify(profile.socialLink));
        postData.append("otherLinks", profile.other_links);
        postData.append("personalDetails", JSON.stringify(profile.personalDetails));
        postData.append("image", profile.personalDetails.photo);
        postData.append("currentJob", JSON.stringify(profile.currentJob));
        postData.append("certificates", JSON.stringify(profile.certificates));
      } else {
        console.log("hitting without photo");
        postData.append("experiance", JSON.stringify(profile.experiance));
        postData.append("education", JSON.stringify(profile.education));
        postData.append("socialLink", profile.socialLink);
        postData.append("otherLinks", profile.other_links);
        postData.append("personalDetails", JSON.stringify(profile.personalDetails));
        postData.append("currentJob", JSON.stringify(profile.currentJob));
        postData.append("certificates", JSON.stringify(profile.certificates));
      }

      const UpldateProfile = await ApplicantProfile.updateApplicantProfile(postData, this.user_id);

      this.$store.commit("RESET_PREFILLED_FLAG");
      this.$store.commit("RESET_PREFILLED_FLAG2");

      this.$toasted.show("Your Profile Successfully Submitted!", {
        position: "top-right",
        duration: 6000,
        icon: "check",
      });
      this.$router.push({ name: "Applicant-Onboarding-4" });
    },

    onSelectDate(e) {
      console.log("e :>> ", e);
      let now = moment().format("YYYY-MM-DD");
      let dob = moment(e);
      if (moment(now).isAfter(dob)) {
        this.personal_details.dob = e; //moment(e).format("YYYY-MM-DD HH:mm:ss");
        let diff = dob.diff(now, "year") * -1;
        if (diff == -0) {
          diff = 0;
        }
        console.log(diff);
        this.personal_details.age = diff;
        console.log("this.personal_details :>> ", this.personal_details);
      } else {
        console.log(false);
        this.$toasted.show("Enter Correct DOB!", {
          duration: 6000,
          icon: "clear",
        });
        this.dob = null;
      }
    },
    onFileChange(e) {
      let input = this.$refs.fileInput;
      let f = e.target.files[0];
      if (f.type == "image/jpeg" || f.type == "image/jpg" || f.type == "image/png") {
        let file = input.files;
        this.personal_details.photo = f;

        if (file && file[0]) {
          let reader = new FileReader();
          reader.onload = (e) => {
            // this.previewImage = e.target.result;
            this.$store.commit("SET_IMAGE_PREVIEW", e.target.result);
          };
          reader.readAsDataURL(file[0]);
          this.$emit("input", file[0]);
        }
        //const file = event.target.files[0];
        //console.log("file :>> ", event);
        // this.image = file;
        // console.log("this.image :>> ", this.image);
      } else {
        this.$toasted.show("This Format is not supported!", {
          duration: 6000,
          icon: "clear",
        });
        // this.previewImage = null;
        this.$store.commit("SET_IMAGE_PREVIEW", null);
      }
    },

    // async ValidateForm() {
    //   try {
    //     await Promise.all([
    //       this.ValidatePersonalDetails(),
    //       this.ValidateCurrentJob(),
    //     ]);
    //     this.$router.push({ name: "Applicant-Onboarding-3" });
    //     return true;
    //   } catch (error) {
    //     return false;
    //   }
    //   // this.ValidatePersonalDetails();
    //   // this.ValidateCurrentJob();
    //   // return true;
    // },
    getAllSkills() {
      ApplicantProfile.getAllSkills().then((skills) => {
        this.skillOptions = skills.data.data.map((skill) => {
          return {
            label: skill.skill_name,
            skill_id: skill.skill_id,
          };
        });
      });
    },
    removedSkills(e) {
      console.log("e :>> ", e);
      this.current_job.delSkills.push(e.skill_id);
    },
    addSkills(e) {
      console.log("e :>> ", e);

      const addSkills = e.map((m) => {
        return m.skill_id;
      });
      console.log("addSkills :>> ", addSkills);
      this.current_job.delSkills = this.current_job.delSkills.filter((f) => {
        if (addSkills.includes(f)) {
          return false;
        } else {
          return true;
        }
      });
    },
    addPersonalDetails() {
      console.log("this.personal_details :>> ", this.personal_details);
      this.$store.dispatch("onHandleApplicantPersonalDetails", this.personal_details);
    },
    addCurrentJob() {
      console.log("this.current_job.skills :>> ", this.current_job.skills);
      let skill = this.current_job.skills;

      this.current_job.skills = skill;

      console.log("this.current_job :>> ", this.current_job);
      this.$store.dispatch("onHandleCurrentJob", this.current_job);
    },
    prefilledDataFromStore() {
      let payload = {
        personal_details: this.$store.getters.getApplicantPersonalDetails,
        currentJob: this.$store.getters.getApplicantCurrentJob,
      };
      let personalDetails = JSON.stringify(payload.personal_details);
      console.log("payload ------:>> ", payload);

      this.personal_details = { ...JSON.parse(personalDetails) };
      // this.personal_details = payload.personal_details;
      this.dob = payload.personal_details.dob;
      // this.personal_details.photo = payload.personal_details.photo;
      // this.personal_details.dob = moment(payload.personal_details.dob).format(
      //   "YYYY-MM-DD hh:mm:ss"
      // );
      console.log("this.personal_details :>> ", this.personal_details);
      this.current_job = {
        available: payload.available,
        delAvail: payload.currentJob.delAvail,
        exp_salary: payload.currentJob && payload.currentJob.exp_salary ? +payload.currentJob.exp_salary : null,
        experiance: payload.currentJob && payload.currentJob.experiance ? +payload.currentJob.experiance : null,
        // experience_months: payload.currentJob
        //   ? +payload.currentJob.experience_months
        //   : null,
        job_org: payload.currentJob ? payload.currentJob.job_org : "",
        job_title: payload.currentJob ? payload.currentJob.job_title : "",
        skills: payload.currentJob ? payload.currentJob.skills : [],
        delSkills: [],
        introduction: payload.currentJob ? payload.currentJob.introduction : "",
      };
      console.log("this.current_job :>> ", this.current_job);
      this.$forceUpdate();
    },
    prefilledData() {
      // if (this.$store.getters.getPrefilledFlag2 == 2) {
      //   this.prefilledDataFromStore();
      // } else {
      let loader = this.$loading.show();
      const payload = { applicant_id: this.$cookies.get("user_id") };

      ApplicantProfile.getApplicantProfile(payload).then((data) => {
        let profile = data.data.data.user_data[0];
       
        this.dob = profile.user_dob;
        // let temp1 = JSON.stringify(
        //   this.$store.getters.getApplicantPersonalDetails
        // );
        // this.personal_details = JSON.parse(temp1);
        //  photo: null,
        // country: null,
        // industry: null,
        // nationality: null,
        // preferred_job: null,
        let personal = {
          photo: profile.user_job_profile_image,

          industry: profile.user_job_industry,
          nationality: { nationality: profile.user_job_nationality },
          preferred_job: { job_title: profile.user_job_preferred },
          currency: {
            currency_id: profile.currency_id,
            currency_name: profile.currency_name,
          },
          phone_number: profile.phone_number,
          phone_country_code: profile.phone_country_code,
          phone_country_number: profile.phone_country_number,
        };
        if (profile.country_id) {
          personal.country = {
            label: profile.country_name,
            country_id: profile.country_id,
          };
        }
        if (profile.city_id) {
          personal.city = {
            label: profile.city_name,
            city_id: profile.city_id,
          };
        }
        if (profile.industry_id) {
          personal.industry = {
            label: profile.industry_name,
            industry_id: profile.industry_id,
          };
        }
        personal.dob = profile.user_dob ? new Date(profile.user_dob) : null;
        console.log("personal :>> ", personal);
        if (personal.photo || personal.country || personal.industry || personal.nationality || personal.preferred_job) {
          this.personal_details = personal;
        }
        //      current_job: {
        //   job_title: null,
        //   introduction: null,
        //   skills: [],
        //   experiance: null,
        // },
        let currentJob = {
          job_title: profile.user_job_title,
          introduction: profile.user_job_introduction,
          job_org: profile.user_job_current_org,
          exp_salary: profile.user_job_expected_salary,
          gender: profile.gender,

          skills: profile.skills.map((s) => {
            return {
              label: s.skill_name,
              skill_id: s.skill_id,
            };
          }),
          speciality_id: profile.speciality_id,
          delSkills: [],
          experiance: profile.user_job_experience,
          delAvail: [],
          available: profile.available,
          // experience_months: profile.user_job_experience_months,
        };
        this.speciality = this.specialityOpt.filter((f) => f.checkbox_id == profile.speciality_id);
        console.log("currentJob :>> ", currentJob);
        if (
          // currentJob.job_title ||
          // currentJob.introduction ||
          // currentJob.experiance
          true
        ) {
          this.current_job = currentJob;
          console.log("currentJob :>> ", currentJob);
        }
        if (!this.current_job.job_title || !this.current_job.job_org) {
          //this.getResumeParserData();
        }
        if (this.personal_details.country) {
          this.onChangeCountry(this.personal_details.country);
        }
        loader.hide();
        // let temp2 = JSON.stringify(this.$store.getters.getApplicantCurrentJob);
        // this.current_job = JSON.parse(temp2);
      });
      //}
    },
  },
};
</script>

<style lang="css" scoped>
.small {
  color: red;
}
.option-dark label {
  font-weight: bold;
  font-size: 16px;
}

.new-form .error-msg {
  margin-top: 0px !important;
}
.multis >>> .multiselect__tags {
  /* color: orange; */
  background-color: #eaeaea;
}
.multis >>> .multiselect__tag {
  background: #6244e5;
}

.multis >>> .multiselect__tag-icon:after {
  color: white;
}

.multis >>> .multiselect__tag-icon:hover {
  background: #6244e5;
}
.multis >>> .multiselect__option--highlight {
  background: #6244e5;
}

.multis >>> .multiselect__option:after {
  background-color: #6244e5;
  color: white;
}

.multiselect__single {
  background: #eaeaea !important;
}

.multiselect-dropdown span {
  margin-right: 10px;
}

.grid-half {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
}

.skills-label label {
  font-family: "pn-regular" !important;
  background: #ececec !important;
  color: #c3c3c3;
  letter-spacing: 1.5px;
  font-size: 13px;
  padding: 05px 10px;
  border-radius: 04px;
  margin-right: 10px;
  font-weight: 300;
}
.phone_number_input {
  background-color: #eaeaea;
}
</style>
